<template>
  <div>
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">BMR Calculator</h5>

        <form @submit.prevent class="my-3">
          <div class="form-floating my-1">
            <input
              v-model.trim="bmrForm.age"
              type="number"
              class="form-control"
              id="bmr-age"
              placeholder="Age (years)"
            >
            <label for="bmr-age">Age (years)</label>
          </div>
          <select
            v-model.trim="bmrForm.gender"
            class="form-select my-1"
            aria-label="Gender"
          >
            <option disabled value="">Please select a gender</option>
            <option value="m">Male</option>
            <option value="f">Female</option>
          </select>
          <div class="form-floating my-1">
            <input
              v-model.trim="bmrForm.height"
              type="number"
              class="form-control"
              id="bmr-height"
              placeholder="Height (cm)"
            >
            <label for="bmr-height">Height (cm)</label>
          </div>
          <div class="form-floating my-1">
            <input
              v-model.trim="bmrForm.weight"
              type="number"
              class="form-control"
              id="bmr-weight"
              placeholder="Weight (kg)"
            >
            <label for="bmr-weight">Weight (kg)</label>
          </div>
          <select
            v-model.trim="bmrForm.activity"
            class="form-select my-1"
            aria-label="Activity"
          >
            <option disabled value="">Please select an activity level</option>
            <option value="1.2">Sedentary (little to no exercise)</option>
            <option value="1.375">Light (exercise 1-3 days per week)</option>
            <option value="1.55">Moderate (exercise 3-5 days per week)</option>
            <option value="1.725">Active (exercise 6-7 days per week)</option>
            <option value="1.9">Extra active (very hard exercise/sports/physical job)</option>
          </select>
        </form>

        <blockquote class="mt-3 fs-6">
          <div>
            <strong class="me-2">BMR</strong>
            <span v-if="bmr">{{ bmr | round }}</span>
            <span v-else class="is-busy">n/a</span>
          </div>
          <div>
            <strong class="me-2">Calories</strong>
            <span v-if="calories">{{ calories | round }}</span>
            <span v-else class="is-busy">n/a</span>
          </div>
        </blockquote>

        <div class="mt-3 small fst-italic text-muted">
          Note: Basal metabolic rate (BMR) is calculated based on the Mifflin-St Jeor equation.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NutritionCalculator',
  computed: {
    bmrFormFilled() {
      const {
        age,
        gender,
        height,
        weight,
        activity,
      } = this.bmrForm;
      return age !== '' && gender !== '' && height !== '' && weight !== '' && activity !== '';
    },
    bmr() {
      if (this.bmrFormFilled) {
        const {
          age,
          gender,
          height,
          weight,
        } = this.bmrForm;

        const a = parseFloat(age);
        const h = parseFloat(height);
        const w = parseFloat(weight);
        const v = gender === 'm' ? 5 : -161;
        return (10 * w) + (6.25 * h) - (5 * a) + v;
      }
      return null;
    },
    calories() {
      if (this.bmr > 0) {
        const { activity } = this.bmrForm;
        return this.bmr * parseFloat(activity);
      }
      return null;
    },
  },
  data() {
    return {
      bmrForm: {
        age: '',
        gender: '',
        height: '',
        weight: '',
        activity: '',
      },
    };
  },
};
</script>
